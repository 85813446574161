import { useEffect } from 'react';
import { setIsMobile } from '@/redux/actions/screen';
import { SiteSpecificComponentContext } from '@orgnc/core/lib/themes/SiteSpecificComponentsProvider';
import BaseApp from '@orgnc/core/lib/wordpress/pages/_app.page';
import { Provider, useDispatch } from 'react-redux';
import '../components/blocks/facebook-embed/facebook-embed-block.css';
import '../components/listenlive.scss';
import '../components/podcast.scss';
import '../components/whiz.scss';

import { getTheme } from '../theme';
import { inter, merriWeather } from '../utils/localFonts';
import { store } from '../redux/store';
import useCanonicalDomain, {
  HostnameProvider,
} from '../utils/useCanonicalDomain';
import { getDomainThemeId } from '../themes';
import StaticApp from '../components/StaticApp';
import { getStatusCode } from '../utils/getStatusCode';

const App: typeof BaseApp = (props: any) => {
  if (props.isError) {
    return (
      <StaticApp>
        <props.Component {...props.pageProps} />
      </StaticApp>
    );
  }

  return (
    <Provider store={store}>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
          font-family: ${merriWeather.style.fontFamily};
        }
      `}</style>
      <HostnameProvider hostname={props.hostname}>
        <AppContent {...props} />
      </HostnameProvider>
    </Provider>
  );
};

const AppContent = (props: any) => {
  const dispatch = useDispatch();
  const domain = useCanonicalDomain();

  useEffect(() => {
    dispatch(setIsMobile(props.isMobile));
  }, [dispatch, props.isMobile]);

  return (
    <SiteSpecificComponentContext.Provider
      value={getTheme(getDomainThemeId(domain))}
    >
      <BaseApp {...props} />
    </SiteSpecificComponentContext.Provider>
  );
};

// App.getInitialProps = BaseApp.getInitialProps;
App.getInitialProps = async (appContext) => {
  const statusCode = getStatusCode(appContext.ctx);
  const isError = statusCode >= 400;
  const { ctx } = appContext;

  // Custom logic to determine `isMobile`
  const isMobile =
    ctx.req?.headers['user-agent']?.toLowerCase().includes('whiz') ||
    ctx.req?.url?.toLowerCase().includes('?whiz');

  const hostname = ctx.req?.headers.host;

  // Call BaseApp's getInitialProps and preserve its output
  const baseAppProps = BaseApp.getInitialProps
    ? await BaseApp.getInitialProps(appContext)
    : { pageProps: {}, requestHost: undefined };

  // Ensure the returned object includes all necessary properties
  return {
    ...baseAppProps, // Contains pageProps and other required properties
    isMobile,
    hostname,
    isError,
  };
};

export default App;
