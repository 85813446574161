import { gql } from 'graphql-request';

const podcastSectionFragment = gql`
  fragment PodcastSectionFragment on CreateBlockBmgGeneralBlocksBlock {
    episodes {
      edges {
        node {
          databaseId
          title
          link
          episodeImageUrl
          episodeAudioUrl
          author {
            node {
              name
            }
          }
        }
      }
    }
  }
`;

export default podcastSectionFragment;
