import {
  Button,
  Drawer,
  IconButton,
  List,
  styled,
  Typography,
} from '@mui/material';

export const StyledOpenSidebarIconButton = styled(IconButton)(({ theme }) => ({
  padding: '0',
  stroke: theme.palette.primary.main,
  strokeWidth: '2',
  fontFamily: 'dashicons, sans-serif',
  width: '32px',
  height: '19px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    maxWidth: '40px',
    height: '24px',
  },
}));

export const StyledOpenSidebarSpan = styled(Typography)<any>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '80%',
  top: '2px',
  height: '3px',
  borderRadius: '3px',
  position: 'absolute',
  left: '0',
  transition: 'transform 0.15s ease',

  '&::before, &::after': {
    content: '""',
    display: 'block',
    height: '3px',
    borderRadius: '3px',
    position: 'absolute',
    left: '0',
    backgroundColor: theme.palette.primary.main,
  },

  '&::before': {
    width: '32px',
    top: '8px',
    transitionProperty: 'transform, opacity',
  },

  '&::after': {
    top: '16px',
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    '&, &::before, &::after': {
      height: '4px',
      borderRadius: '4px',
    },

    '&::before': {
      width: '40px',
      top: '8px',
    },

    '&::after': {
      top: '16px',
    },
  },
}));

export const StyledSidebarWrapper = styled(Drawer)(({ theme }) => ({
  '& .sidebar-content': {
    padding: '0',
    width: '300px',
    height: '100vh',
    backgroundColor: theme.palette.brand.header,
    color: 'inherit',

    '& .mobile-menu-list': {
      padding: '0 !important',
    },

    '& li': {
      padding: '0 !important',
    },
  },
}));

export const StyledCloseSidebarBtn = styled(IconButton)(({ theme }) => ({
  padding: '0',
  fontWeight: '700',
  marginLeft: '15px',
  marginTop: '15px',
  stroke: theme.palette.primary.main,
  strokeWidth: '2',
  fontFamily: 'dashicons, sans-serif',

  '& svg': {
    width: '32px',
    height: '32px',
  },
}));

export const StyledSocialIconsList = styled(List)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '10px 14px !important',

  '& li': {
    all: 'unset',

    '& a': {
      display: 'block',
      height: '25px',
      width: '25px',
      background: '#f5f5f5',
      color: '#333',
      lineHeight: '1',
      margin: '5px 10px',
      padding: '0',
      '&:hover': {
        background: '#f5f5f5',
      },
    },
  },
});

export const StyledMobileMenuList = styled(List)({
  '& li': {
    padding: 0,
  },
});

export const StyledMobileMenuLink = styled(Button)(({ theme }) => ({
  fontSize: '19px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  padding: '14px 15px',
  fontWeight: '600',
  lineHeight: '1',
  color: theme.palette.brand.text,
  width: '100%',
  justifyContent: 'space-between',

  '& svg': {
    transition: 'transform 0.15s ease',
    strokeWidth: 2,
    stroke: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },

  '&.active': {
    color: theme.palette.primary.main,

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  '&:hover, &.active': {
    backgroundColor: theme.palette.brand.headerHighlight,
  },
}));

export const StyledMobileSubMenu = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.brand.headerHighlight,

  '& li a': {
    padding: '10px 18px',
    textTransform: 'capitalize',
    whiteSpace: 'break-spaces',
    fontWeight: '500',
    fontSize: '15px',
    textDecoration: 'none',
    textAlign: 'left',
    width: '100%',
    color: theme.palette.brand.text,
  },

  [theme.breakpoints.up('xl')]: {
    '& > div': {
      borderRadius: 'unset',
      boxShadow: 'unset',

      '& ul': {
        minWidth: '250px',
        maxWidth: '350px',
        backgroundColor: theme.palette.brand.headerHighlight,

        '& li a': {
          padding: '5px',
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          fontWeight: '400',
          fontSize: '12px',
          textDecoration: 'none',
          textAlign: 'left',
          width: '100%',
          color: theme.palette.brand.text,
        },
      },
    },
  },
}));
