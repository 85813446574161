import Close from '@mui/icons-material/Close';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, Container, Link, ListItem } from '@mui/material';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import { ISocialItem } from '@orgnc/core/lib/wordpress/api/adapters/layoutDataAdapter';
import { IMenuItemNode } from '@orgnc/core/lib/wordpress/api/adapters/menuDataAdapter';
import { Fragment, useState } from 'react';
import { OptionalLinkWrapper } from '../optional-link-wrapper/optional-link-wrapper';
import { Searchbar } from '../searchbar/searchbar';
import { SocialMediaBlock } from '../social-media-block/social-media-block';
import {
  StyledCloseSidebarBtn,
  StyledMobileMenuLink,
  StyledMobileMenuList,
  StyledMobileSubMenu,
  StyledOpenSidebarIconButton,
  StyledOpenSidebarSpan,
  StyledSidebarWrapper,
  StyledSocialIconsList,
} from './mobile-menu-style';

export const MobileMenu = ({
  aNavigations,
  networks,
}: {
  aNavigations: IMenuItemNode[];
  networks: ISocialItem[];
}) => {
  const [sidebarVisible, setSidebarVisibility] = useState<boolean>(false);
  const [openSubMenu, setOpenSubMenu] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleToggleSubMenu = (parentMenuId: string) => {
    setOpenSubMenu({
      ...openSubMenu,
      [parentMenuId]: !openSubMenu[parentMenuId],
    });
  };

  const closeAll = () => {
    setSidebarVisibility(false);
    setOpenSubMenu({});
  };

  return (
    <Fragment>
      <StyledOpenSidebarIconButton onClick={() => setSidebarVisibility(true)}>
        <StyledOpenSidebarSpan component="span" />
      </StyledOpenSidebarIconButton>

      <StyledSidebarWrapper
        anchor="left"
        open={sidebarVisible}
        onClose={closeAll}
        sx={{ display: { xs: 'block', xl: 'none' } }}
        // className={classes.sidebarWrapper}
      >
        <Container className="sidebar-content">
          <StyledCloseSidebarBtn
            onClick={closeAll}
            // className={classes.closeSidebarBtn}
          >
            <Close fontSize="large" />
          </StyledCloseSidebarBtn>

          <Searchbar isMobile={true} />

          <StyledSocialIconsList
            sx={{ display: 'flex', flexWrap: 'nowrap' }}
            // className={classes.socialIcons}
          >
            {networks.length > 0 && <SocialMediaBlock networks={networks} />}
          </StyledSocialIconsList>

          <StyledMobileMenuList
            className="mobile-menu-list"
            //  className={classes.menuList}
          >
            {aNavigations.map(
              ({ children, id, label, path }: IMenuItemNode, index) => {
                const hasChildren = children && children.length > 0;
                const open = hasChildren && openSubMenu[id];

                const props: any = hasChildren
                  ? {
                      onClick: () => handleToggleSubMenu(id),
                      endIcon: <KeyboardArrowDown />,
                      'aria-controls': open ? 'fade-menu' : undefined,
                      'aria-haspopup': 'true',
                      'aria-expanded': open ? 'true' : undefined,
                    }
                  : {
                      component: 'a',
                    };

                const isRootButtonLink = !hasChildren;

                return (
                  <Fragment key={`menu_navigation_${index}`}>
                    <ListItem>
                      <OptionalLinkWrapper
                        isLink={isRootButtonLink}
                        href={(path as string) ?? '#'}
                      >
                        <StyledMobileMenuLink
                          className={open ? 'active' : ''}
                          {...props}
                        >
                          {label}
                        </StyledMobileMenuLink>
                      </OptionalLinkWrapper>
                    </ListItem>

                    {hasChildren && (
                      <Collapse in={open} unmountOnExit>
                        <StyledMobileSubMenu
                        // className={classes.subMenu}
                        >
                          {children.map((subMenu) => (
                            <ListItem
                              key={`sidebar_sub_menu_${subMenu.parentId}_${subMenu.id}`}
                            >
                              <DisablePrefetchLink
                                href={(subMenu.path as string) || '#'}
                                passHref
                              >
                                <Link>{subMenu.label}</Link>
                              </DisablePrefetchLink>
                            </ListItem>
                          ))}
                        </StyledMobileSubMenu>
                      </Collapse>
                    )}
                  </Fragment>
                );
              }
            )}
          </StyledMobileMenuList>
        </Container>
      </StyledSidebarWrapper>
    </Fragment>
  );
};
