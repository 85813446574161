import {
  FeatureLayoutPlayerButton,
  FeatureLayoutStations,
} from '@/components/Player';
import bmgSettingsQuery from '@/lib/queries/bmgSettingsQuery';
import { isAudioAdOnly } from '@/lib/utilities';
import { STATUSES, playStation } from '@/redux/actions/player';
import { AppDispatch } from '@/redux/store';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalQuery } from '@orgnc/core/hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadTdSk } from '../utils/tdSdk';

type Props = {
  colors?: {
    '--global-theme-secondary'?: string;
    '--brand-button-color'?: string;
    '--brand-background-color'?: string;
    '--brand-text-color'?: string;
  };
};

const STATUS_LABELS: Record<string, string> = {
  [STATUSES.LIVE_PAUSE]: 'Paused',
  [STATUSES.LIVE_PLAYING]: 'On Air',
  [STATUSES.LIVE_STOP]: 'Listen Live',
  [STATUSES.LIVE_FAILED]: 'Stream unavailable',
  [STATUSES.LIVE_BUFFERING]: 'Buffering...',
  [STATUSES.LIVE_CONNECTING]: 'Live stream connection in progress...',
  [STATUSES.LIVE_RECONNECTING]: 'Reconnecting live stream...',
  [STATUSES.STREAM_GEO_BLOCKED]:
    'Sorry, this content is not available in your area',
  [STATUSES.STATION_NOT_FOUND]: 'Station not found',
};

interface StreamCuePointProps {
  text: string;
}

const StreamCuePoint: React.FC<StreamCuePointProps> = ({ text }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return () => {}; // Always return a function

    const textEl = container.querySelector<HTMLSpanElement>('.scroll-text');
    if (!textEl) return () => {};

    // Function to update animation based on text width
    const updateAnimation = () => {
      if (textEl.scrollWidth > container.offsetWidth) {
        textEl.classList.add('animate');
      } else {
        textEl.classList.remove('animate');
      }
    };

    // Initial check
    updateAnimation();

    // Create a mutation observer to watch for text changes
    const observer = new MutationObserver(() => {
      updateAnimation();
    });

    observer.observe(textEl, {
      childList: true,
      characterData: true,
      subtree: true,
    });

    // Also update on window resize in case container width changes
    window.addEventListener('resize', updateAnimation);

    // Cleanup on unmount
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateAnimation);
    };
  }, [text]);

  const decodeHtmlEntities = useCallback((html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent || '';
  }, []);

  const decodedText = useMemo(
    () => decodeHtmlEntities(text),
    [text, decodeHtmlEntities]
  );

  return (
    <div className="stream-cue-point-data" ref={containerRef}>
      <span className="scroll-text">{decodedText}</span>
    </div>
  );
};

const ListenLive: React.FC<Props> = () => {
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true); // Initially disabled
  const [currentIndexStream, setCurrentIndexStream] = useState(0);
  const [topStream, setTopStream] = useState<any>({});
  const [allStreams, setAllStreams] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const { data: bmgGeneralConfiguration } = useGlobalQuery(bmgSettingsQuery);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { picture } = topStream;

  const sponsoredByVisibility =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.sponsoredByVisibility ?? false;
  const sponsoredBrandName =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.sponsoredBrandName ?? '';
  const sponsoredByLogo =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.sponsoredByLogo?.mediaItemUrl ?? '';
  const dispatch: AppDispatch = useDispatch();
  const {
    station,
    streams,
    status,
    cuePoint,
    time,
    duration,
    adPlayback,
    player,
    playerType,
    adSynced,
  } = useSelector((state: any) => state.player);

  const lastPodcastArtist = useRef<string>('');
  const lastPodcastTitle = useRef<string>('');

  // Utility function for formatting time
  const formatTime = (timeVar: number) => {
    const HOUR_IN_SECONDS = 3600;
    const MINUTE_IN_SECONDS = 60;
    const hours = Math.floor(timeVar / HOUR_IN_SECONDS);
    const minutes = Math.floor((timeVar % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS);
    const seconds = Math.floor(timeVar % MINUTE_IN_SECONDS);
    const toFixed = (value: number) =>
      value.toString().length === 2 ? value.toString() : `0${value}`;
    let result = `${toFixed(minutes)}:${toFixed(seconds)}`;
    if (hours > 0) {
      result = `${toFixed(hours)}:${result}`;
    }
    return result;
  };

  useEffect(() => {
    const updateHeight = () => {
      const header = document.querySelector('header') as HTMLElement;
      const listenMusic = document.querySelector(
        '.Listen_music'
      ) as HTMLElement;
      if (header && listenMusic) {
        if (window.innerWidth <= 900) {
          listenMusic.style.top = `${header.clientHeight + 10}px`;
        } else {
          listenMusic.style.top = `101px`;
        }
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    const openListener = () => setIsVisible(true);
    window.addEventListener('openListenLive', openListener);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('openListenLive', openListener);
    };
  }, []);

  useEffect(() => {
    const expandButton = document.querySelector(
      '.expand_more'
    ) as HTMLElement | null;
    const submenu = document.querySelector('.submenu') as HTMLElement | null;
    const listenMusic = document.querySelector(
      '.Listen_music'
    ) as HTMLElement | null;

    if (!expandButton || !submenu || !listenMusic) return;

    expandButton.addEventListener('click', (event) => {
      event.preventDefault();

      const submenuHeight = submenu.scrollHeight;
      if (submenu.classList.contains('open')) {
        submenu.style.height = `${submenuHeight}px`; // Set fixed height before collapsing
        requestAnimationFrame(() => {
          submenu.style.height = '0'; // Collapse
        });
      } else {
        submenu.style.height = `${submenuHeight}px`; // Expand to natural height
      }

      submenu.classList.toggle('open');
      listenMusic.classList.toggle('active');

      submenu.addEventListener(
        'transitionend',
        () => {
          if (submenu.classList.contains('open')) {
            submenu.style.height = 'auto'; // Reset height to auto when expanded
          } else {
            submenu.style.height = '0'; // Keep height 0 when collapsed
          }
        },
        { once: true }
      ); // Only trigger once per transition
    });
  }, []);

  useEffect(() => {
    if (streams && streams.length > 0) {
      const matchedIndex = streams.findIndex(
        (stream: any) => stream.stream_call_letters === station
      );
      if (matchedIndex !== -1) {
        setTopStream(streams[matchedIndex]);
      } else {
        setTopStream(streams[0]);
      }
      setAllStreams(streams);
    }
  }, [streams, station]);

  const playCurrentStream = (stream: any) => {
    if (stream.stream_call_letters) {
      loadTdSk()
        .then(() => {
          dispatch(playStation(stream.stream_call_letters));
        })
        .catch(() => {});
    }
  };

  const nextStream = () => {
    const newIndex = currentIndexStream + 1;
    if (newIndex < allStreams.length) {
      setCurrentIndexStream(newIndex); // Update the current index
      const nextStreamData = allStreams[newIndex];
      setTopStream(nextStreamData); // Update the top stream
      playCurrentStream(nextStreamData); // Play the next stream
      if (newIndex === allStreams.length - 1) {
        setIsNextDisabled(true); // Disable the "Next" button
      }
    }
    setIsPrevDisabled(false); // Ensure "Previous" is enabled
  };

  const prevStream = () => {
    const newIndex = currentIndexStream - 1;
    if (newIndex >= 0) {
      setCurrentIndexStream(newIndex); // Update the current index
      const prevStreamData = allStreams[newIndex];
      setTopStream(prevStreamData); // Update the top stream
      playCurrentStream(prevStreamData); // Play the previous stream
      if (newIndex === 0) {
        setIsPrevDisabled(true); // Disable the "Previous" button
      }
    }
    setIsNextDisabled(false); // Ensure "Next" is enabled
  };

  const getCuePointInfo = (cuePointData: any) => {
    let displayString: string = '';
    if (!cuePointData) return false;

    const { artistName, cueTitle, type } = cuePointData;
    if (type === 'ad') return false;

    if (!station) {
      lastPodcastArtist.current = artistName;
      lastPodcastTitle.current = cueTitle;
    }

    if (
      station &&
      lastPodcastArtist.current === artistName &&
      lastPodcastTitle.current === cueTitle
    )
      return false;

    if (cueTitle !== '' && artistName !== '') {
      displayString = `${cueTitle} - ${artistName}`;
    } else if (cueTitle !== '') {
      displayString = `${cueTitle}`;
    } else if (artistName !== '') {
      displayString = `${artistName}`;
    }
    return displayString;
  };

  const getControlMarkup = (
    title: string,
    pictureData: string,
    // author = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    description = '',
    playerData = ''
  ) => {
    // const playerButtonDiv = document.getElementById('player-button-div');
    // const buttonsFillStyle = {};

    // if (playerButtonDiv) {
    // 	const customColors = JSON.parse(playerButtonDiv.dataset.customColors || '{}');
    // 	buttonsFillStyle.fill =
    // 		customColors['--brand-music-control-color'] ||
    // 		customColors['--global-theme-secondary'];
    // 	buttonsFillStyle.stroke =
    // 		customColors['--brand-music-control-color'] ||
    // 		customColors['--global-theme-secondary'];
    // }

    // const isIos = isIOS();
    // const volumeControl = isIos ? null : <Volume colors={buttonsFillStyle} />;

    return (
      <div className="on-air-list ll-top-container">
        <div className="topstream link">
          <div className="Listen_music_image">
            <a href="#">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={pictureData}
                alt={title}
                style={{
                  width: '56px',
                  height: '56px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            </a>
          </div>
          <div className="listen_music_content">
            <p className="status">
              <span className="pulsating-circle"></span>
              Live
            </p>
            <h2>{title}</h2>
            {playerData === 'cuePoint' && (
              <>
                <p className="by_author">
                  <span className="author_name">
                    &nbsp; {/* by {author} */}
                  </span>{' '}
                  <span>
                    {' '}
                    {formatTime(time)} / {formatTime(duration)}
                  </span>
                </p>
              </>
            )}
            {playerData === 'stream' && (
              <>
                <StreamCuePoint text={description} />
              </>
            )}
          </div>
          <div className="action_button">
            <div className="flex_property play_icon">
              <button
                onClick={prevStream}
                disabled={isPrevDisabled}
                type="button"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  textDecoration: 'underline',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SkipPreviousOutlinedIcon aria-label="Skip previous" />
              </button>
              <FeatureLayoutPlayerButton inDropDown />
              <button
                onClick={nextStream}
                disabled={isNextDisabled}
                type="button"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  textDecoration: 'underline',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SkipNextOutlinedIcon aria-label="Skip next" />
              </button>
              <button
                type="button"
                className="expand_more"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  textDecoration: 'underline',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <OpenInFullIcon
                  aria-label="Expand player"
                  sx={{
                    width: '19px',
                    height: '19px',
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getAudioInfo = () => {
    const { cueTitle, image } = cuePoint;
    return getControlMarkup(
      cueTitle || null,
      image || null,
      // artistName || null,
      '',
      'cuePoint'
    );
  };

  const getStationInfo = () => {
    let info: any = STATUS_LABELS[status] || '';
    if (status === 'LIVE_PLAYING') {
      const pointInfo = getCuePointInfo(cuePoint);
      if (pointInfo) {
        info = pointInfo;
      }
    }

    const stream = streams.find(
      (item: any) => item.stream_call_letters === station
    );
    return getControlMarkup(
      stream?.title || station,
      stream?.picture.original.url,
      // '',
      info,
      'stream'
    );
  };

  const children = station ? getStationInfo() : getAudioInfo();

  return (
    <>
      <div
        className={`preroll-wrapper${
          adPlayback && !isAudioAdOnly({ player, playerType }) ? ' -active' : ''
        }`}
      >
        <div className="preroll-container">
          <div id="td_container" className="preroll-player" />
          <div className="preroll-notification -hidden">
            Live stream will be available after this brief ad from our sponsors
          </div>
        </div>
      </div>
      <div id="gam_ad_container"></div>
      <div className={`Listen_music ${isVisible ? 'visible' : ''}`}>
        <div
          className="close_icon"
          onClick={(event) => {
            event.stopPropagation();
            setIsVisible(false);
          }}
        >
          <CloseIcon
            area-label="close icon"
            sx={{ color: 'white', fontSize: 15 }}
          />
        </div>
        {children}
        <ul className="bottomstreamlist submenu">
          <FeatureLayoutStations />
        </ul>
        {sponsoredByVisibility && (
          <div className="link bottom_most">
            <p className="by_author">Delivered by</p>
            <div className="action_button">
              {(sponsoredBrandName || sponsoredByLogo) && (
                <p className="by_author">
                  {sponsoredByLogo && (
                    /* eslint-disable-next-line @next/next/no-img-element */
                    <img
                      src={`${sponsoredByLogo}?auto=webp&width=11`}
                      alt="NETA Brookline"
                    />
                  )}
                  {sponsoredBrandName}
                </p>
              )}
            </div>
          </div>
        )}

        <div id="sync-banner" className={adSynced ? '' : '-hidden'} />
      </div>
    </>
  );
};

export default ListenLive;
