import b1077thebounceTheme from './sites/1077thebounce';
import augustatodayTheme from './sites/augustatoday';
import b1027VGSTheme from './sites/b1027vgs';
import coyotecountrylvTheme from './sites/coyotecountrylv';
import espnswflTheme from './sites/espnswfl';
import foxy99Theme from './sites/foxy99';
import hd983Theme from './sites/hd983';
import hotaugustaTheme from './sites/hotaugusta';
import ilovebobfmTheme from './sites/ilovebobfm';
import kicks99Theme from './sites/kicks99';
import magic983Theme from './sites/magic983';
import mykissradioTheme from './sites/mykissradio';
import playjackradioTheme from './sites/playjackradio';
import sunny1027Theme from './sites/sunny1027';
import sunny943Theme from './sites/sunny943';
import wdhafmTheme from './sites/wdhafm';
import wgacTheme from './sites/wgac';
import wjrzTheme from './sites/wjrz';
import wkmlTheme from './sites/wkml';
import wmtramTheme from './sites/wmtram';
import wratTheme from './sites/wrat';
import wtmrradioTheme from './sites/wtmrradio';
import { SiteType } from './type';

const THEMES = {
  [SiteType.ESPNSWFL]: espnswflTheme,
  [SiteType.B1027VGS]: b1027VGSTheme,
  [SiteType.AUGUSTATODAY]: augustatodayTheme,
  [SiteType.COYOTECOUNTRYLV]: coyotecountrylvTheme,
  [SiteType.FOXY99]: foxy99Theme,
  [SiteType.WKML]: wkmlTheme,
  [SiteType.PLAYJACKRADIO]: playjackradioTheme,
  [SiteType.B1077THEBOUNCE]: b1077thebounceTheme,
  [SiteType.MYKISSRADIO]: mykissradioTheme,
  [SiteType.SUNNY943]: sunny943Theme,
  [SiteType.WRAT]: wratTheme,
  [SiteType.MAGIC983]: magic983Theme,
  [SiteType.WDHAFM]: wdhafmTheme,
  [SiteType.WJRZ]: wjrzTheme,
  [SiteType.WMTRAM]: wmtramTheme,
  [SiteType.WTMRRADIO]: wtmrradioTheme,
  [SiteType.WGAC]: wgacTheme,
  [SiteType.KICKS99]: kicks99Theme,
  [SiteType.HD983]: hd983Theme,
  [SiteType.HOTAUGUSTA]: hotaugustaTheme,
  [SiteType.SUNNY1027]: sunny1027Theme,
  [SiteType.ILOVEBOBFM]: ilovebobfmTheme,
};

export const getDomainThemeId = (domain: string | null = null) => {
  switch (domain) {
    case 'coyotecountrylv.com':
      return SiteType.COYOTECOUNTRYLV;
    case '1027vgs.com':
      return SiteType.B1027VGS;
    case 'augustatoday.com':
      return SiteType.AUGUSTATODAY;
    case 'espnswfl.com':
      return SiteType.ESPNSWFL;
    case 'foxy99.com':
      return SiteType.FOXY99;
    case 'wkml.com':
      return SiteType.WKML;
    case 'playjackradio.com':
      return SiteType.PLAYJACKRADIO;
    case '1077thebounce.com':
      return SiteType.B1077THEBOUNCE;
    case 'mykissradio.com':
      return SiteType.MYKISSRADIO;
    case 'sunny943.com':
      return SiteType.SUNNY943;
    case 'wrat.com':
      return SiteType.WRAT;
    case 'magic983.com':
      return SiteType.MAGIC983;
    case 'wdhafm.com':
      return SiteType.WDHAFM;
    case 'wjrz.com':
      return SiteType.WJRZ;
    case 'wmtram.com':
      return SiteType.WMTRAM;
    case 'wtmrradio.com':
      return SiteType.WTMRRADIO;
    case 'wgac.com':
      return SiteType.WGAC;
    case 'kicks99.com':
      return SiteType.KICKS99;
    case 'hd983.com':
      return SiteType.HD983;
    case 'hotaugusta.com':
      return SiteType.HOTAUGUSTA;
    case 'sunny1027.com':
      return SiteType.SUNNY1027;
    case 'ilovebobfm.com':
      return SiteType.ILOVEBOBFM;

    default:
      // By default just drop domain and use it as theme id
      return domain?.replace('.com', '') ?? SiteType.ESPNSWFL;
  }
};

export const getMuiTheme = (themeId: SiteType | string | undefined) => {
  if (themeId === undefined || !(themeId in THEMES)) {
    return espnswflTheme;
  }

  return THEMES[themeId as SiteType];
};

// Simplify imports for storybook
export const defaultTheme = getMuiTheme(process.env.STORYBOOK_THEME_ID ?? '');
