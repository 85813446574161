import { Button, styled } from '@mui/material';

export const StyledMyListenerControllerButton = styled(Button)<any>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderTopLeftRadius: '22.5px !important',
    borderBottomLeftRadius: '22.5px !important',
    backgroundColor: `${theme.palette.brand.listenLive} !important`,
    color: '#ffffff !important',
    height: '45px',
    fontSize: '14px',
    fontWeight: '700 !important',
    padding: '0 10px',
    borderRadius: 0,
    justifyContent: 'center',

    '& span': {
      marginLeft: 0,
      fontSize: 'inherit',
      fontWeight: 'inherit',
      '& svg': {
        strokeWidth: 2,
      },
    },

    '& .status': {
      width: '28px',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: '#e63838',

      '& .btn-default': {
        background: 'none',
      },

      '& button': {
        width: '29px',
        height: '29px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `#ffffff !important`,
        borderRadius: '100px',
        cursor: 'pointer',
        border: '0px',

        '& .loading': {
          '&::after': {
            border: `0.1em solid #000000`,
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
          },
        },

        '& div': {
          display: 'flex',
        },

        '& svg': {
          width: '13px',
          height: '12px',
          fill: '#000000',

          '& rect, & path': {
            fill: '#000000',
          },
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      height: '50px',
      fontSize: '18px',
      padding: '0 10px',
      '& > span > svg.play-icon': {
        fontSize: '35px',
      },
    },
  })
);
